@charset 'utf-8';
@import "~bulma/sass/utilities/functions";
@import './styles/custom_colors';

$custom-colors: (
  "custom-purple": ($custom-purple),
  "custom-purple-darker": ($custom-purple-darker),
  "custom-green": ($custom-green),
  "custom-red": ($custom-red),
  "custom-yellow": ($custom-yellow),
);

// Import Bulma styles
@import "~bulma/bulma";

// Import rbx style fixes
@import "~rbx/rbx";

html {
  background-color: var(--background);
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 16px; // barra de rolagem vertical
  }

  &::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: hsl(0,0%,67%);;
  }
}

.label {
  font-weight: unset;
  font-size: 0.9rem;
}

.label:not(:last-child) {
  margin-bottom: 0.2rem;
}

.input {
  background-color: var(--background);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: revert;
}

.hr {
  background-color: transparent;
  border-color: rgb(221, 221, 221);
  border-style: dashed;
  border-width: 1px 0px 0px;
  margin: 0.9rem 0;
}

[disabled].input {
  border-color: hsl(0deg, 0%, 90%);
}

.table td, .table th {
  vertical-align: middle;
}

.menu {
  font-size: 0.9rem;
}

.menu-list li {
  line-height: 1.5;
}

.title {
  color: var(--text-title);
}

.subtitle {
  color: var(--text-body);
}

.bd-color {
  border-radius: 2px;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,.1), inset 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  height: 24px;
  margin-right: 8px;
  width: 24px;
}

@media (max-width: 768px) {
  .modal-content, .modal-card {
    width: 96%;
  }

  .menu {
    font-size: 1.2rem;
  }

  .dropdown-item {
    font-size: 1.1rem;
  }

  .menu-list li {
    line-height: 2;
  }
}
